/*
* 업무구분: 신탁>고객상품>고객투자자현황분석>설문등록>투자성향재진단P
* 화 면 명: MSPTS104M
* 화면설명: [7005] 고객성향 설문등록 > 투자성향재진단 POPUP
* 접근권한: 
* 작 성 일: 2023.03.06
* 작 성 자: 지재호
*/
<template>
  <mo-modal class="fts-modal medium s-medium noClose" ref="modal1" title="재진단설문조사">
      <template>
        <div class="wrap-modalcontents">
          <div class="wrap-input column">
            <p class="text">
              금일 투자성향 재진단 실시한 고객으로<br/>
              그 사유를 선택해 주세요.
            </p>
            <div class="row w100p">
              <mo-dropdown :items="popupObj.cmb_retryRsn" v-model='popupObj.retryInsertRsnTc' placeholder=" " ref="dropdown1" />
            </div>
          </div>
        </div>
      </template>
      <template slot="action">
        <div class="wrap-button button-page-bottom row">
          <!-- <mo-button point size="large" @click="closeModal(1)"> 닫기 </mo-button> -->
          <mo-button primary size="large" @click="closeModal(1)"> 입력 </mo-button>
        </div>
      </template>
    </mo-modal>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import TSCommUtil from '~/src/ui/ts/comm/TSCommUtil'

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPTS114P",
  screenId: "MSPTS114P",
  props: {
      popupObj: {type:Object, default:null},
  },
  components: {},
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {},
  mounted() {
    this.$bizUtil.insSrnLog("MSPTS114P");
    document.getElementsByClassName('wrap-modalcontents')[0].addEventListener("scroll", this.closeDropdown)
  },
  beforeDestroy() {
    document.getElementsByClassName('wrap-modalcontents')[0].removeEventListener("scroll", this.closeDropdown)
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      valueSelect01: '1',
      cmbRetryList: [],
      retryInsertRsnTc: '',
    };
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    // modal
    modal1() {return this.$refs.modal1},
    select01() {
      let rtn = [];
      rtn.push({value: '0', text: '사유 01'});
      rtn.push({value: '1', text: '사유 02'});
      return rtn;
    }
  },
  /***********************************************************************************
   * watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {},
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    // modal
    openModal(type) {
      this["modal"+type].open()
    },
    closeModal(type) {
      if ( this.popupObj.retryInsertRsnTc == '' ) {
        TSCommUtil.gfn_validate(this, '재진단 사유를 선택해주십시오.')
        return 
      }
      this.$emit('ts-popup-callback', {}) // 메인화면에 변경값 고정되어 있음
      this.closeDropdown()
      this["modal"+type].close()
    },
    closeDropdown() {
      this.$refs.dropdown1.close()
    },

    fn_Open() {
      this.popupObj.retryInsertRsnTc = ''
      this.modal1.open()
    },
    fn_Close() {
      this.modal1.close()
      this.closeDropdown()
    },

    // 부모 함수 호출
    fn_AlertPopup() {
        let t_popupObj = {
          confirm: false,
          content: '실명번호가 올바르지 않습니다.'
        }
        this.$emit('ts-alert-popup', 0, t_popupObj)
    }
  }
};
</script>
