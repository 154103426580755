import { render, staticRenderFns } from "./MSPTS104M.vue?vue&type=template&id=141a34dc&scoped=true&"
import script from "./MSPTS104M.vue?vue&type=script&lang=js&"
export * from "./MSPTS104M.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "141a34dc",
  null
  
)

export default component.exports