/*
* 업무구분: 신탁>고객상품>고객투자자현황분석>설문등록
* 화 면 명: MSPTS104M
* 화면설명: [7005] 고객성향 설문등록
* 접근권한: 
* 작 성 일: 2023.02.20
* 작 성 자: 지재호
* 파 일 명: TFCP11000
* trnstId 변경 : (AsIs) svcId ==> (ToBe) trnstId
*   selectTFCP11000 ==> C392_F10600043_S 
*   selectTFCP11000A ==> C392_F10600045_S 
*/
<template>
  <ur-page-container class="fts" :show-title="false" title="고객성향 설문등록" :topButton="true">

    <!-- header start -->
    <ts-header
      ref="tsHeader"
      :propObj="pHeaderObj"
    ></ts-header>
    <!-- header end -->

    <!-- .fts-main -->
    <div class="fts-main">
      
      <ur-box-container direction="column" alignV="start">
        <div class="input-top">
          <div class="left">
            <div class="wrap-input row">
              <label class="emphasis"> 실명번호 종류 </label>
              <mo-dropdown :items="cmb_rcnoKindTc" v-model="ds_search.rcnoKindTc" ref='cmb_rcnoKindTc' class="w150" />
            </div>
            <div class="wrap-input row">
              <label class="emphasis"> 실명번호 </label>
              
              <mo-text-field placeholder="앞 6자리" class="w80"
                v-model="rcnoOne"
                maxlength="6"
                type="number" 
                @keyup="[fn_SearchValid($event, 'edt_rcnoOne'), fn_validErrRemoveInput($event, 'edt_rcnoOne')]" 
                @paste="fn_SearchValid($event, 'P')"
                ref="edt_rcnoOne" 
              />
              <m-trans-key-input placeholder="뒤 7자리" class="w80"
                v-if="isMobile"
                v-model="rcnoTwo" 
                type="numberMax7"
                dialog="Y"
                start="1" 
                end="-1"
                :isClear="lv_isClear"
                @masked-txt="fn_SetMaskedTxt"         
                ref="edt_rcnoTwo" 
              />
              <mo-text-field placeholder="뒤 7자리" class="w80"
                v-else
                type="password"
                v-model="rcnoTwo" 
                numeric 
                mask="#######"
                @keyup="[fn_SearchValid($event, 'edt_rcnoTwo'), fn_validErrRemoveInput($event, 'edt_rcnoTwo')]"  
                ref="edt_rcnoTwo" 
              />
            </div>
            <div class="wrap-input row">
              <label> 고객명 </label>
              <mo-text-field class="form-input-name w140" 
                v-model="ds_detail.cnm"
                :class="valid.cnm.error ? 'error' : ''"
                clearable placeholder=" " 
                ref="edt_cnm"
                disabled
              />
            </div>
          </div>
          <div class="right">
            <div class="wrap-button row">
              <mo-button point class="clear" v-if="lv_serverType==='local'" @click="fn_movePage_MSPTS208M"> 208m 테스트 </mo-button>
              <mo-button point class="clear" v-if="lv_serverType==='local'" @click="print_test"> 서식 테스트11 </mo-button>
              <mo-button point class="clear" @click="fn_init"> 초기화 </mo-button>
              <mo-button primary class="inquiry" @click="fn_searchList" :disabled="enabledList.btn_searchList ==false"> 조회 </mo-button>
            </div>
          </div>
        </div>
      </ur-box-container>
      
      <ur-box-container direction="column" alignV="start">
        <div class="wrap-desc mt-5">
          <p>
            본 확인서는 [금융소비자 보호에 관한 법률]에 따라 고객의 투자자정보를 파악하여, 그에 적합한 투자권유를 해 드리기 위한 기초자료로 활용됩니다. <br/>
            고객의 상황에 부합하거나 가장 가까운 항목을 정확히 선택하여 주시기 바랍니다.
          </p>
          <p>
            본 확인서는 고객님의 투자 유형을 분류하고 유형에 적합한 방식으로 투자금융을 운용하기 위해서 금전신탁계약을 체결하고자 하는 경우에는 반드시 작성하셔야 합니다.
          </p>
        </div>

        <div class="input-middle row">
          <div class="wrap-input row">
            <label> 고객성향 </label>
            <mo-dropdown placeholder=" " class="w167" disabled
              :items="cmb_csIvtdRatingC" 
              v-model="ds_detail.csIvtdRatingC" 
            />
          </div>
          <div class="wrap-input row">
            <label> 분석날짜 </label>
            <mo-date-picker disabled v-model="ds_detail.procDt" :bottom="false" />
          </div>
        </div>

        <div class="wrap-desc red">
          <p v-if="sta_notice" >
            전문투자자는 설문에 응답하지 않습니다.
          </p>
        </div>
      </ur-box-container>

      <ur-box-container direction="column" alignV="start">
        <div class="wrap-table table-radio-01">
          <table class="table col-type col2030">
            <tbody>
              <tr>
                <th>
                  <span class="emphasis"> 일반/전문금융소비자 여부 </span>
                </th>
                <td colspan="3">
                  <div class="wrap-radio radio-list row justify-between">
                    <mo-radio-wrapper 
                      class="row" 
                      :items="rdo_genSpivrTc" 
                      v-model="ds_detail.genSpivrTc" 
                      :disabled="enabledList.genSpivrTc==false" 
                      :class="valid.genSpivrTc.error ? 'error' : ''"
                      @click="valid.genSpivrTc.error = ''"
                      @input="fn_commonClick"
                      ref="rdo_genSpivrTc"
                    />
                    <p class="colorR text-xs row">※ 단, 전문금융소비자 중 일반금융소비자와 같은 대우를 받길 원하는 경우 일반금융소비자에 체크</p>
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span class="emphasis"> 투자 권유 희망 여부 </span>
                </th>
                <td colspan="3">
                  <div class="wrap-radio radio-list">
                    <mo-radio-wrapper 
                    class="row"
                    :items="rdo_ivivHopTc" 
                    v-model="ds_detail.ivivHopTc" 
                    :class="valid.ivivHopTc.error ? 'error' : ''"
                    @click="valid.ivivHopTc.error = ''"
                    ref="rdo_ivivHopTc"
                    disabled
                  />
                  </div>
                </td>
              </tr>
              <tr class="separate">
                <th>
                  <span class="emphasis"> 유효 기간 설정 여부 </span>
                </th>
                <td>
                  <mo-radio-wrapper 
                    class="row"
                    :items="valdTrmYys" 
                    v-model="ds_detail.valdTrmYys" 
                    :disabled="enabledList.valdTrmYys==false" 
                    :class="valid.valdTrmYys.error ? 'error' : ''"
                    @click="valid.valdTrmYys.error = ''"
                    ref="rdo_valdTrmYys"
                  />
                </td>
                <th>
                  <span> 유효기간 </span>
                </th>
                <td>
                  <mo-range-picker v-model='moRange' :bottom="false" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="wrap-table table-radio-02 no-border-bottom">
          <table class="table col-type">
            <tbody>
              <tr>
                <th> <p class="list-num" :class="endabledQues.ques01==false ? 'disabled' : ''"> <i>1.</i> 고객님의 연령대는 어떻게 되십니까? </p> </th>
                <td>
                  <mo-radio-wrapper class="column"
                    :items="result01" 
                    v-model="ds_detail.ques01" 
                    :class="valid.ques01.error ? 'error' : ''"
                    :disabled="endabledQues.ques01==false" 
                    @click="valid.ques01.error = ''"
                    @input="fn_commonClick"
                    ref="rdo_ques01"
                  />
                </td>
              </tr>
              <tr>
                <th> <p class="list-num" :class="endabledQues.ques02==false ? 'disabled' : ''"> <i>2.</i> 다음 중 고객님의 투자 경험과 가장 가까운 것은 어느 것입니까? </p> </th>
                <td>
                  <mo-radio-wrapper 
                    :items="result02" 
                    v-model="ds_detail.ques02" 
                    :class="valid.ques02.error ? 'error' : ''"
                    :disabled="endabledQues.ques02==false" class="column"
                    @click="valid.ques02.error = ''" 
                    ref="rdo_ques02"
                  />
                </td>
              </tr>
              <tr>
                <th> <p class="list-num" :class="endabledQues.ques03==false ? 'disabled' : ''"> <i>3.</i> 고객님께서는 금융상품 투자에 대한 본인의 수준이 어느 정도라고 생각하십니까? </p> </th>                <td>
                  <mo-radio-wrapper 
                    :items="result03" 
                    v-model="ds_detail.ques03" 
                    :class="valid.ques03.error ? 'error' : ''"
                    :disabled="endabledQues.ques03==false" class="column"
                    @click="valid.ques03.error = ''" 
                    ref="rdo_ques03"
                  />
                </td>
              </tr>
              <tr>
                <th> <p class="list-num" :class="endabledQues.ques04==false ? 'disabled' : ''"> <i>4.</i> 고객님께서 보유하고 있는 금융자산은 고객님의 전체 자산 중 어느정도 비중을 차지합니까? </p> </th>
                <td>
                  <mo-radio-wrapper 
                    :items="result04" 
                    v-model="ds_detail.ques04" 
                    :class="valid.ques04.error ? 'error' : ''"
                    :disabled="endabledQues.ques04==false" class="column"
                    @click="valid.ques04.error = ''"  
                    ref="rdo_ques04"
                  />
                </td>
              </tr>
              <tr>
                <th> <p class="list-num" :class="endabledQues.ques05==false ? 'disabled' : ''"> <i>5.</i> 고객님의 수입원을 가장 잘 나타내는 것은 어느 것입니까? </p> </th>
                <td>
                  <mo-radio-wrapper 
                    :items="result05" 
                    v-model="ds_detail.ques05" 
                    :class="valid.ques05.error ? 'error' : ''"
                    :disabled="endabledQues.ques05==false" class="column"
                    @click="valid.ques05.error = ''"  
                    ref="rdo_ques05"
                  />
                </td>
              </tr>
              <tr>
                <th> <p class="list-num" :class="endabledQues.ques06==false ? 'disabled' : ''"> <i>6.</i> 고객님의 연간 소득현황은 어떻게 되십니까? </p> </th>
                <td>
                  <mo-radio-wrapper 
                    :items="result06" 
                    v-model="ds_detail.ques06" 
                    :class="valid.ques06.error ? 'error' : ''"
                    :disabled="endabledQues.ques06==false" class="column"
                    @click="valid.ques06.error = ''"  
                    ref="rdo_ques06"
                  />
                </td>
              </tr>
              <tr>
                <th> <p class="list-num" :class="endabledQues.ques07==false ? 'disabled' : ''"> <i>7.</i> 고객님의 금융투자상품의 투자 경험기간은 얼마나 되십니까? </p> </th>
                <td>
                  <mo-radio-wrapper 
                    class="column"
                    :items="result07" 
                    v-model="ds_detail.ques07" 
                    :class="valid.ques07.error ? 'error' : ''"
                    :disabled="endabledQues.ques07==false" 
                    @click="valid.ques07.error = ''"  
                    @input="fn_commonClick"
                    ref="rdo_ques07"
                  />
                </td>
              </tr>
              <tr>
                <th> <p class="list-num" :class="endabledQues.ques08==false ? 'disabled' : ''"> <i>8.</i> 고객님의 금융투자상품 거래 빈도는 어떻게 되십니까? </p> </th>
                <td>
                  <mo-radio-wrapper 
                    class="column"
                    :items="result08" 
                    v-model="ds_detail.ques08" 
                    :class="valid.ques08.error ? 'error' : ''"
                    :disabled="endabledQues.ques08==false" 
                    @click="valid.ques08.error = ''"  
                    @input="fn_commonClick"
                    ref="rdo_ques08"
                  />
                </td>
              </tr>
              <tr>
                <th> <p class="list-num" :class="endabledQues.ques09==false ? 'disabled' : ''"> <i>9.</i> 고객님의 금융투자상품 취득 및 처분 금액(연간)은 어느정도 되십니까? </p> </th>
                <td>
                  <mo-radio-wrapper 
                    class="column"
                    :items="result09" 
                    v-model="ds_detail.ques09" 
                    :class="valid.ques09.error ? 'error' : ''"
                    :disabled="endabledQues.ques09==false" 
                    @click="valid.ques09.error = ''"  
                    @input="fn_commonClick"
                    ref="rdo_ques09"
                  />
                </td>
              </tr>
              <tr>
                <th> <p class="list-num" :class="endabledQues.ques10==false ? 'disabled' : ''"> <i>10.</i> 고객님의 해당 금융투자상품을 취득 및 처분하시는 주된 목적은 무엇입니까? </p> </th>
                <td>
                  <mo-radio-wrapper 
                    class="column"
                    :items="result10" 
                    v-model="ds_detail.ques10" 
                    :class="valid.ques10.error ? 'error' : ''"
                    :disabled="endabledQues.ques10==false" 
                    @click="valid.ques10.error = ''"  
                    @input="fn_commonClick"
                    ref="rdo_ques10"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="wrap-table table-radio-03 no-border-top special">
          <div class="wrap-desc">
            <p> 현재 투자자금의 성향에 대한 질문사항 입니다. </p>
          </div>
          <table class="table col-type">
            <tbody>
              <tr>
                <th> <p class="list-num" :class="endabledQues.ques11==false ? 'disabled' : ''"> <i>11.</i> 고객님께서 투자하고자 하는 자금의 투자가능 기간은 얼마나 되십니까? </p> </th>
                <td>
                  <mo-radio-wrapper 
                    class="column"
                    :items="result11" 
                    v-model="ds_detail.ques11" 
                    :class="valid.ques11.error ? 'error' : ''"
                    :disabled="endabledQues.ques11==false" 
                    @click="valid.ques11.error = ''"  
                    ref="rdo_ques11"
                  />
                </td>
              </tr>
              <tr>
                <th> <p class="list-num" :class="endabledQues.ques12==false ? 'disabled' : ''"> <i>12.</i> 고객님의 금융상품 투자목적은 어떻게 되십니까? </p> </th>
                <td>
                  <mo-radio-wrapper 
                    class="column"
                    :items="result12" 
                    v-model="ds_detail.ques12" 
                    :class="valid.ques12.error ? 'error' : ''"
                    :disabled="endabledQues.ques12==false" 
                    @click="valid.ques12.error = ''"  
                    ref="rdo_ques12"
                  />
                </td>
              </tr>
              <tr>
                <th> <p class="list-num" :class="endabledQues.ques13==false ? 'disabled' : ''"> <i>13.</i>  고객님의 투자기대수익 및 손실을 감내할 수 있는 수준은 어느 것입니까? </p> </th>
                <td>
                  <mo-radio-wrapper 
                    class="column"
                    :items="result13" 
                    v-model="ds_detail.ques13" 
                    :class="valid.ques13.error ? 'error' : ''"
                    :disabled="endabledQues.ques13==false" 
                    @click="valid.ques13.error = ''"  
                    ref="rdo_ques13"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="wrap-table table-radio-04 no-border-top">
          <table class="table col-type">
            <tbody>
              <tr>
                <th> <p class="list-num" :class="endabledQues.ques14==false ? 'disabled' : ''"> <i>14.</i>  금융취약소비자에 해당되십니까? </p> </th>
                <td>
                  <mo-radio-wrapper 
                    class="column"
                    :items="result14" 
                    v-model="ds_detail.ques14" 
                    :class="valid.ques14.error ? 'error' : ''"
                    :disabled="endabledQues.ques14==false" 
                    @click="valid.ques14.error = ''"  
                    ref="rdo_ques14"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="wrap-desc">
          <p> 금융취약소비자 : 만 65세 이상 고령투자자, 미성년자, 정상적 판단에 장애가 있는 투자자, 금융상품투자 무경험자, 은퇴자, 주부, 문맹자 등 </p>
          <p> 금융취약소비자의 경우 : “금융소비자보호법“에 따라 금융소비자의  불이익사항을 다른 정보보다 우선하여 설명 받으실 수 있습니다. </p>
        </div>

        <div class="wrap-desc">
          <p> 귀사에 제공한 본인의 투자목적, 재산상황 및 투자경험 등의 투자자정보가 정확함을 확인합니다. </p>
          <p> 향후 유효기간 동안 귀사는 본인의 투자자정보가 변경되지 않은 것으로 간주한다는 점을 설명 받았습니다. </p>
          <p> 본인의 투자자정보에 변경사항이 발생한 경우에는 이를 귀사에 통지하여야 귀사가 본인에게 적합한 투자권유를 할 수 있다는 점을 설명 받았습니다. </p>
        </div>
		  </ur-box-container>

      <ur-box-container>
        <div class="wrap-button button-page-bottom row">
          <mo-button primary size="large" v-if="lv_serverType==='local'" @click='fn_isValid'> fn_isValid </mo-button>
          <mo-button primary size="large" v-if="lv_serverType==='local'" @click='focus_test'> 포커스 테스트 </mo-button>
          <mo-button primary size="large" :disabled="enabledList.btn_confirm==false" @click='fn_confirm'> 확인 </mo-button>
        </div>
      </ur-box-container>
    </div>
    <!-- //.fts-main -->

    <!-- alert modal popup -->
    <ts-alert-popup 
      ref="alertPopup"
      :popupObj="pAlertPopupObj"
      @childReturnEvt="returnEvt"
    ></ts-alert-popup>

    <ts-alert-popup
      ref="alertPopupSec"
      :popupObj="pAlertPopupObj"
      @childReturnEvt="returnEvt"
    ></ts-alert-popup>

    <!-- popup114 금일 투자성향 재진단 -->
    <msp-ts-114p 
      ref="popup114"
      @ts-alert-popup="fn_AlertPopup"
      @ts-popup-callback="fn_Popup_CallBack"      
      :popupObj="retryRsnObj"
    ></msp-ts-114p>

  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * DataSet 영역	                                                                    *
   ***********************************************************************************/
  const defaultDsDetail = {  

    // // 기존 ds_search
    // rcnoKindTc: '',
    // rcno: '',
    // bizDate: '',
    // csId: '',
    
    cnm: '',
    qtnSeq: '',
    lastChngDt: '',
    lastChngMnTmno: '',
    lastChngMnUsid: '',
    procDt: '', // 분석날짜
    genSpivrTc: '', // 일반/전문금융소비자 여부
    ivivHopTc: '', // 투자 권유 희망 여부
    valdTrmYys: '', // 유효 기간 설정 여부 
    ivivNohoYn: '', // 투자권유불원(확인서)
    ques01: '',
    ques02: '',
    ques03: '',
    ques04: '',
    ques05: '',
    ques06: '',
    ques07: '',
    ques08: '',
    ques09: '',
    ques10: '',
    ques11: '',
    ques12: '',
    ques13: '',
    ques14: '',
    ques15: '',
    quesTotScr: '',
    csIvtdRatingC: '0',
    estbRegSysTc: '',
    cnt: '',
    aprvNo: '',
    aprvNo2: '',
    retryInsertRsnTc: '', // 금일 투자성향 재진단 선택값
  }

  // 문항 endabl dataSet
  const defaultEnabledQues = { 
    ques01: false, // 무슨 상황이 오든 1번 문항은 disable로 귀결됨...
    ques02: true,
    ques03: true,
    ques04: true,
    ques05: true,
    ques06: true,
    ques07: true,
    ques08: true,
    ques09: true,
    ques10: true,
    ques11: true,
    ques12: true,
    ques13: true,
    ques14: true,
  }
  
  // disabled 
  const defaultEnabledList = { 
    btn_confirm: true,    // 확인 저장 버튼
    btn_searchList: true, // 조회 버튼
    btn_cancel: true,     // 취소
    btn_init: true,       // 초기화
    genSpivrTc: true,     // 일반/전문금융소비자 여부
    ivivHopTc: true,      // 투자권유불원(확인서)
    valdTrmYys: true,     // 유효기간 설정 여부
    cnm: true,
    rcno: true,
  }

  const defaultDsSearch = {
    rcnoKindTc: '01',
    rcno: '',
    bizDate: '',
    csId: '', //952342771
  }

  const defaultCsIvtdRatingC = [
    {value: '0', text: '정보미제공'},
    {value: '1', text: '공격투자형'},
    {value: '2', text: '적극투자형'},
    {value: '3', text: '위험수익중립형'},
    {value: '4', text: '안정추구형'},
    {value: '5', text: '안정형'},
  ]

  const defaultValid = { 
    rcno: { error:false , title: '실명번호' },  
    cnm: { error:false , title: '고객명' }, 
    genSpivrTc: { error:false , title: '일반/전문금융소비자 여부' }, // 일반/전문금융소비자 여부
    ivivHopTc: { error:false , title: '투자 권유 희망 여부' }, // 투자 권유 희망 여부
    valdTrmYys: { error:false , title: '유효 기간 설정 여부 ' }, // 유효 기간 설정 여부 
    ques01: { error:false , title: '1번 문항' },
    ques02: { error:false , title: '2번 문항' },
    ques03: { error:false , title: '3번 문항' },
    ques04: { error:false , title: '4번 문항' },
    ques05: { error:false , title: '5번 문항' },
    ques06: { error:false , title: '6번 문항' },
    ques07: { error:false , title: '7번 문항' },
    ques08: { error:false , title: '8번 문항' },
    ques09: { error:false , title: '9번 문항' },
    ques10: { error:false , title: '10번 문항' },
    ques11: { error:false , title: '11번 문항' },
    ques12: { error:false , title: '12번 문항' },
    ques13: { error:false , title: '13번 문항' },
    ques14: { error:false , title: '14번 문항' },
    ques15: { error:false , title: '15번 문항' },
  }

  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  // import TSCommUtil from '~/src/ui/ts/comm/TSCommUtil'  
  // import TSCommDateUtil from '~/src/ui/ts/comm/TSCommDateUtil'  
  import TSServiceUtil from '~/src/ui/ts/comm/TSServiceUtil'
  import TSCommUtil from '~/src/ui/ts/comm/TSCommUtil'
  import TSAlertPopup from '~/src/ui/ts/comm/TSAlertPopup'  // Alert 팝업 (공통)
  import MSPTS114P from '~/src/ui/ts/MSPTS114P'  // 투자성향재진단 팝업
  import TSHeader from '~/src/ui/ts/comm/TSHeader'          // header 영역 (공통)
  import TSInfoUtil    from '~/src/ui/ts/comm/TSInfoUtil'    
  

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPTS104M",
    screenId: "MSPTS104M",
    components: {
      'ts-header': TSHeader,
      'ts-alert-popup': TSAlertPopup,
      'msp-ts-114p': MSPTS114P,
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() { 
      console.log('104M rcno :::>>>',this.$route.params.rcno)
      //계좌정보
      if ( !TSCommUtil.gfn_isNull(this.$route.params.processGb) ){
        this.processGb = this.$route.params.processGb;
      }
      if ( !TSCommUtil.gfn_isNull(this.$route.params.tacno) ){
        this.tacno = this.$route.params.tacno;
      }
      if ( !TSCommUtil.gfn_isNull(this.$route.params.bnkbSeq) ){
        this.bnkbSeq = this.$route.params.bnkbSeq;
      }
      if ( !TSCommUtil.gfn_isNull(this.$route.params.accSeq) ){
        this.accSeq = this.$route.params.accSeq;
      }
      this.$nextTick(()=>{
        if ( !TSCommUtil.gfn_isNull(this.$route.params.rcnoKindTc) ) {
          this.ds_search.rcnoKindTc = this.$route.params.rcnoKindTc
        }
        if ( !TSCommUtil.gfn_isNull(this.$route.params.rcno) ) {
          // this.ds_search.rcno = this.$route.params.rcno
          let t_rcnoOne = this.$route.params.rcno.substr(0,6)
          let t_rcnoTwo = this.isMobile ? '' : this.$route.params.rcno.substr(6,7)
          this.rcnoOne = t_rcnoOne
          this.rcnoTwo = t_rcnoTwo
          
          // 고객번호 매핑 후 조회
          // this.fn_SearchValid({key: 'v'})
          if ( this.isMobile )            
            this.fn_AlertPopup(0,{ content : '실명번호 뒷자리를 입력해주시길 바랍니다.'} )
          else // 모바일이 아닐 경우 자동검색, 모바일일 경우 무조건 보안패드로 입력 받는다
            this.fn_searchList()
        }
      })
      this.lv_serverType = process.env.NODE_ENV
    },
    mounted() {
      //TODO : 주석 풀고 사용해야함 
      this.fn_afterFormOnload()
      this.fn_init()
      this.fn_comCode() 

      /**
       * 가능하면 data 에서 초기화 진행중
       */

      // 폼로드시 호출, 초기화버튼 클릭시 호출
      this.retryInsertRsnCheck = false; //2022-07-19 add 당일재진단 시 사유입력 체크 
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        // 공통 객체
        pHeaderObj: {          
          title: '설문등록',
          type: 'custIvtrPrpnsAnly',
          step: 1,
        },
        // 보안키패드 관련
        isMobile: window.vue.getStore('deviceState').getters.getIsMobile,
        isMtrans: this.getStore('fsStore').getters.getState.isMtrans,
        lv_isClear: false,     // 보안키패드 초기화 
        lv_masked_rcnoTwo: '', // 실명번호 (보안키패드 '*' 치환값)
        
        lv_basInfo: this.getStore('tsStore').getters.getBasInfo.data,
        lv_serverType : '',

        hid_rcno: '', // 조회 response rcno값 저장
        rcnoOne: '', // 실명번호 첫번째   
        rcnoTwo: '', // 실명번호 두번째   

        // visible
        sta_notice: '', // 전문투자자는 설문에 응답하지 않습니다.
        moRange: ['', ''], // 유효기간
        cmb_csIvtdRatingC: [],

        // dom 객체 enable, disable 여부 
        enabledList: Object.assign({}, defaultEnabledList),
        
        // 검색  dataSet
        ds_detail: Object.assign({}, defaultDsDetail),
        // ds_detail_rst: Object.assign({}, defaultDsDetailRst),
        ds_suikInfo: Object.assign({}, defaultDsDetail),

        ds_search: Object.assign({}, defaultDsSearch),

        // 설문 문항 model
        endabledQues: Object.assign({}, defaultEnabledQues), // 설문 문항 endable 여부
        
        // nexacro 전역 
        div01Disabled: true, // div01 영역 disabled flag
        div02Disabled: false, // div02 영역 disabled flag
        div03Disabled: false, // div03 영역 disabled flag
        
        // 글로벌
        strToday: '',
        sSvcID: '',
        snbGdTc: '',
        suik_check: 'T',
        gBizDate: '',
        superAge: '',
        retryInsertRsnCheck: false, // 투자성향 재진단 flag 변수
        
        // Contents Model 
        eaiCommObj: TSCommUtil.gfn_eaiCommObj(),

         // 팝업 객체
        pAlertPopupObj: {}, // 공통 객체
        pPopup103Obj: {}, // 전용 팝업 객체
        pPopup114Obj: {}, // 전용 팝업 객체
        
        // 공통코드 
        cmb_rcnoKindTc: [], // 실명번호 종류
        chk_ivivNohoYn: '', // 투자권유불원(확인서)

        // 금일 투자성향 재진단, isnert시에 ds_detail 에 적용한다.
        retryRsnObj: {
          cmb_retryRsn: [],  // 금일 투자성향 재진단 콤보 리스트
          retryInsertRsnTc: '', // 금일 투자성향 재진단 선택값
        },
	  
        lv_isProcess: this.getStore('tsStore').getters.getState.isProcess, // 프로세스 체크 

        // valid 체크 
        valid: _.cloneDeep(defaultValid),

        //계좌정보(MSPTS200M)
        processGb: "",    //업무구분: 1.신규 2.추가입금 3.변경
        tacno: "",        //종합계좌번호
        bnkbSeq: "",      //통장일련번호
        accSeq: "",       //계좌일련번호
        //계좌정보(MSPTS200M) End.
        csId: '',         // 해당 실명번호 csId
      }
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      // 실명번호 두번째 검증 비교할값
      valid_rcnoTwo() {
        if ( this.isMobile ) { // 보안키패드 
          return this.lv_masked_rcnoTwo
        } else { // 보안키패드가 아닌경우
          return this.rcnoTwo
        }
      },
      rdo_genSpivrTc() { // 일반/전문금융소비자 여부
        let rtn= [];
        rtn.push({value: '2', text: '일반금융소비자'});
        rtn.push({value: '1', text: '전문금융소비자'});
        return rtn;
      },
      rdo_ivivHopTc() { // 투자권유 희망 여부
        let rtn= [];
        rtn.push({value: '1', text: '고객정보를 제공하여 투자권유 희망'});
        rtn.push({value: '2', text: '고객정보를 제공하지 않으며, 투자권유를 희망하지 않음'});
        return rtn;
      },
      valdTrmYys() {
        let rtn= [];
        rtn.push({value: '1', text: '1년'});
        rtn.push({value: '2', text: '2년'});
        return rtn;
      },
      result01() {
        let rtn= [];
        rtn.push({value: '1', text: '19세 이하'});
        rtn.push({value: '2', text: '20세 ~ 40세'});
        rtn.push({value: '3', text: '41세 ~ 64세'});
        rtn.push({value: '4', text: '65세 ~ 79세'});
        rtn.push({value: '5', text: '80세 이상'});
        rtn.push({value: '6', text: '해당없음'});
        return rtn;
      },
      result02() {
        let rtn= [];
        rtn.push({value: '1', text: '은행 예·적금, 국채, 지방채, MMF, CMA 등'});
        rtn.push({value: '2', text: '금융채, 신용도가 높은 회사채, 채권형 펀드, 원금보장형 ELF 등'});
        rtn.push({value: '3', text: '신용도 중간 등급의 회사채, 원금의 일부만 보장되는 ELF, 혼합형펀드 등'});
        rtn.push({value: '4', text: '신용도가 낮은 회사채, 주식, 원금이 보장되지 않는 ELF, 시장수익률 수준의 수익을 추구하는 주식형펀드 등'});
        rtn.push({value: '5', text: 'ELW, 선물옵션, 시장수익률 이상의 수익을 추구하는 주식형 펀드, 파생상품을 투자하는 펀드, 신용거래 등'});
        return rtn;
      },
      result03() {
        let rtn= [];
        rtn.push({value: '1', text: '주식, 펀드, 채권 등 금융투자상품에 대해 전혀 모른다.'});
        rtn.push({value: '2', text: '주식, 펀드, 채권 등 금융투자상품을 구별할 수 있다.'});
        rtn.push({value: '3', text: '주식, 펀드, 채권 등 금융투자상품에 대해 알고 있으며, 설명을 들으면 투자결정을 내릴 수 있다.'});
        rtn.push({value: '4', text: '파생상품을 포함하여 금융투자상품을 잘 알고 있으며, 스스로 투자 결정을 내릴 수 있다.'});
        return rtn;
      },
      result04() {
        let rtn= [];
        rtn.push({value: '1', text: '5% 이내'});
        rtn.push({value: '2', text: '5% 이상 ~ 10% 이내'});
        rtn.push({value: '3', text: '10% 이상 ~ 20% 이내'});
        rtn.push({value: '4', text: '20% 이상'});
        return rtn;
      },
      result05() {
        let rtn= [];
        rtn.push({value: '1', text: '현재 일정한 수입이 발생하고 있으며, 향후 현재 수준을 유지하거나 증가할 것으로 예상'});
        rtn.push({value: '2', text: '현재 일정한 수입이 발생하고 있으나, 향후 감소하거나 불안정할 것으로 예상'});
        rtn.push({value: '3', text: '현재 일정한 수입이 없으며, 연금이 주 수입원임'});
        return rtn;
      },
      result06() {
        let rtn= [];
        rtn.push({value: '1', text: '1천만원 이하'});
        rtn.push({value: '2', text: '3천만원 이하'});
        rtn.push({value: '3', text: '5천만원 이하'});
        rtn.push({value: '4', text: '1억원 이하'});
        rtn.push({value: '5', text: '1억원 초과'});
        return rtn
      },
      result07() {
        let rtn= [];
        rtn.push({value: '1', text: '전혀 없음'});
        rtn.push({value: '2', text: '1년 미만'});
        rtn.push({value: '3', text: '3년 미만'});
        rtn.push({value: '4', text: '5년 미만'});
        rtn.push({value: '5', text: '5년 이상'});
        return rtn;
      },
      result08() {
        let rtn= [];
        rtn.push({value: '1', text: '매일'});
        rtn.push({value: '2', text: '주간'});
        rtn.push({value: '3', text: '월간'});
        rtn.push({value: '4', text: '분기 1회'});
        rtn.push({value: '5', text: '연 1회'});
        return rtn;
      },
      result09() {
        let rtn= [];
        rtn.push({value: '1', text: '1백만원 이하'});
        rtn.push({value: '2', text: '1천만원 이하'});
        rtn.push({value: '3', text: '1억원 이하'});
        rtn.push({value: '4', text: '1억원 초과'});
        return rtn;
      },
      result10() {
        let rtn= [];
        rtn.push({value: '1', text: '학비'});
        rtn.push({value: '2', text: '생활비'});
        rtn.push({value: '3', text: '주택마련'});
        rtn.push({value: '4', text: '자산증식'});
        rtn.push({value: '5', text: '채무상환'});
        return rtn;
      },
      result11() {
        let rtn= [];
        rtn.push({value: '1', text: '6개월 이내'});
        rtn.push({value: '2', text: '6개월 이상 ~ 1년 이내'});
        rtn.push({value: '3', text: '1년 이상 ~ 2년 이내'});
        rtn.push({value: '4', text: '2년 이상 ~ 3년 이내'});
        rtn.push({value: '5', text: '3년 이상'});
        return rtn;
      },
      result12() {
        let rtn= [];
        rtn.push({value: '1', text: '적극적 매매를 통한 수익을 원하며 원금을 초과하는 손실위험도 감내가능'});
        rtn.push({value: '2', text: '적극적 매매를 통한 수익 실현 목적'});
        rtn.push({value: '3', text: '시장가격(예: 주가지수) 변동 추이와 비슷한 수준의 수익 실현'});
        rtn.push({value: '4', text: '채권이자, 주식배당 정도의 수익 실현 목적'});
        rtn.push({value: '5', text: '기존 보유자산에 대한 위험 해지 목적'});
        return rtn;
      },
      result13() {
        let rtn= [];
        rtn.push({value: '1', text: '무슨 일이 있어도 투자 원금이 보전되어야 한다.'});
        rtn.push({value: '2', text: '이자율 수준의 수익 기대 및 손실을 감내할 수 있다.'});
        rtn.push({value: '3', text: '시장(주가지수)에 비례한 수익 기대 및 손실을 감내할 수 있다.'});
        rtn.push({value: '4', text: '시장수익률을 초과하는 수익 기대 및 손실을 감내할 수 있다.'});
        return rtn;
      },
      result14() {
        let rtn= [];
        rtn.push({value: '1', text: '예'});
        rtn.push({value: '2', text: '아니오'});
        return rtn;
      },
    },
    /***********************************************************************************
     * watch 함수 정의 영역                                                         *
     ***********************************************************************************/
    watch: {
      rcnoOne: function() {
        this.ds_search.rcno = this.rcnoOne.toString() + this.rcnoTwo.toString()
      },
      rcnoTwo: function() {
        this.ds_search.rcno = this.rcnoOne.toString() + this.rcnoTwo.toString()
      },

      'ds_detail.ivivHopTc': {
        handler: function(val, oldVal) {
          this.valid.ivivHopTc.error = ''
        },
      },

      'ds_detail.valdTrmYys': {
        handler: function(val, oldVal) {
          this.valid.valdTrmYys.error = ''
        },
      },


    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
       * Function명 : fn_movePage_MSPTS208M
       * 설명       : 신탁출금화면으로 이동
       ******************************************************************************/
      fn_movePage_MSPTS208M () {
        let ds_move = {}

        ds_move.acno     = '1101794999'
        ds_move.accSeq   = '006'

        console.log('fn_movePage_MSPTS208M ds_move ===========> ',ds_move)

        this.$router.push({name: "MSPTS208M", params: ds_move})
      },

      /******************************************************************************
       * Function명 : fn_validErrRemoveInput
       * 설명       : input, mo-text-field 컴포넌트의 css error class 삭제
       ******************************************************************************/
      fn_validErrRemoveInput (event, el) {
        const value = event.currentTarget.value
        const _el = this.$refs[el].$el
        if ( ! TSCommUtil.gfn_isNull( TSCommUtil.gfn_trim(value) ) )
          _el.classList.remove('error')
      },
      
      /******************************************************************************
      * Function명 : fn_SetMaskedTxt
      * 설명       : 보안키패드 체크
      ******************************************************************************/
      fn_SetMaskedTxt(val) {
        this.lv_masked_rcnoTwo = val
        if ( ! TSCommUtil.gfn_isNull( TSCommUtil.gfn_trim(val) ) )
          this.$refs['edt_rcnoTwo'].$el.classList.remove('error')
      },

      /******************************************************************************
       * Function명 : fn_SearchValid
       * 설명       : 필수값 검증 후 조회 
       ******************************************************************************/
      fn_SearchValid (event, flag) {
        console.log('fn_SearchValid.....')

        let t_rcno
        if( flag == 'P' ) {
          t_rcno = (event.clipboardData || window.clipboardData).getData('text').replace(/[^0-9]/g, '').substr(0,13)
          let t_rcnoOne = t_rcno.substr(0,6)
          let t_rcnoTwo = this.isMobile ? '' : t_rcno.substr(6,7)
          this.rcnoOne = t_rcnoOne
          this.rcnoTwo = t_rcnoTwo

          if( TSCommUtil.gfn_length(t_rcno) == 13 ) {
            if( !TSCommUtil.gfn_isNum(event.key) && event.key !='Enter' && event.key != 'v' && !this.isMobile ) {
              return 
            }
            this.fn_searchList()
          }
        } else if ( flag == 'edt_rcnoOne' ) {
          t_rcno = this.rcnoOne
          if( TSCommUtil.gfn_length(t_rcno) == 6 ) {
            this.$refs['edt_rcnoTwo'].focus()
          }
        } else if ( flag == 'edt_rcnoTwo' ) { 
          if( TSCommUtil.gfn_length(this.valid_rcnoTwo) == 7 ) {
            if( !TSCommUtil.gfn_isNum(event.key) && event.key !='Enter' && event.key != 'v' && !this.isMobile ) {
              return 
            }
            this.fn_searchList()
          }
        }
      },

      /******************************************************************************
       * Function명 : removeError
       * 설명       : css error 삭제
       ******************************************************************************/
      removeError (target) {
        
        // 현재 뎁스만 param 으로 넘겨받아 처리
        // target.classList.remove('error')

        // 부모 뎁스 까지 확인
        if ( target.parentElement.parentElement.classList.contains('error') ) {
          target.parentElement.parentElement.classList.remove('error')
        }
        else if ( target.parentElement.classList.contains('error') ) {
          target.parentElement.classList.remove('error')
        }
        else {
          target.classList.remove('error')
        }
      },

      // 분석결과 이동
      // fn_RstRedirect () {
      //   // const reportParam = this.ds_search;
      //   //this.$router.push({ name: 'MSPTS105M', params: { reportParam: JSON.stringify(reportParam) } })
      //   this.$router.push({name:'MSPTS105M',
      //     params:{
      //       rcnoKindTc : this.ds_search.rcnoKindTc,
      //       rcno : this.ds_search.rcno,
      //       processGb : this.processGb,
      //       tacno : this.tacno,
      //       bnkbSeq : this.bnkbSeq,
      //       accSeq : this.accSeq,
      //     }})  
      // },

      /**********************************************************************************
       * 초기화 : 화면입출력 초기화/활성화
       **********************************************************************************/
      fn_commonAfterOnload ()
      {
        this.ds_search = Object.assign({}, defaultDsSearch)
        this.ds_detail = Object.assign({}, defaultDsDetail)
        // this.fn_comCode() // 원하는 결과와 맞지 않음
      },

      // 팝업 확인, 닫기 event
      returnEvt (value) {
        console.log ( 'emit 이벤트 : ' +  value)
      },
      
      /**********************************************************************************
       * 팝업 콜백 함수 정의 
      **********************************************************************************/
      // 전문 금융소비자 상태로 진행 팝업 콜백
      popCallbackConfirmGenSpivrTc () { // confirm(확인) 클릭시
        this.fn_enableControl("T")
      }, 

      // 전문 금융소비자 상태로 진행 팝업 콜백
      popCallbackCloseGenSpivrTc () { // close(취소) 클릭시
        this.endabledQues = Object.assign({}, defaultEnabledQues)
        this.ds_detail.genSpivrTc = "2"
        this.fn_commonClick();
      }, 

      /**********************************************************************************
       * #조회, 취소, 초기화... 버튼 (넥사크로:div01)
      **********************************************************************************/
      fn_setEnableBtn (param) {
        this.enabledList.btn_searchList  = param // 조회
        this.enabledList.btn_confirm     = param // 확인 저장버튼
        this.enabledList.btn_cancel      = param // 취소
        this.enabledList.btn_init        = param //초기화
      },

      /**********************************************************************************
       * #설문 라디오 버튼 (넥사크로:div02)
      **********************************************************************************/
      fn_setEnableQuesRdo (param) {
        for(let key in this.endabledQues) {
            this.endabledQues[key] = param 
        }
        this.endabledQues.ques01 = false // 무슨 상황이 오든 1번 문항은 disable로 귀결됨...
      },

      /**********************************************************************************
       * #투자성향 재진단 팝업 (넥사크로:div03)
      **********************************************************************************/
      fn_setEnableRetryRsn (param) {
        alert(this.test)
      },

      /**********************************************************************************
       * #당일 재진단 사유 입력창 오픈 2022-07-19 add
      **********************************************************************************/
      fn_retryInsertRsn (param)
      {
        // open , close
        switch (param) 
        {
          case "open":
            // let rtn = [];
            // rtn.push({value: '0', text: '사유 !@!@!@'});
            // rtn.push({value: '1', text: '사유 $#@$#$#'});
            // this.ds_detail.cmbRetryList = rtn
            // this.ds_detail.retryInsertRsnTc = '1'
            this.$refs.popup114.fn_Open()
            break;
          case "close":
            this.$refs.popup114.fn_Close()
            break;
        }
      },

      /**********************************************************************************
       * 조회(수익증권영역 고객성향 설문지 조회) : WFtitleCRUDbtn 의 callback
       **********************************************************************************/
      fn_SuikSelect () {
        // this.eaiCommObj.lv_vm = this
        // this.eaiCommObj.trnstId = 'txTSSTS14S2' // selectTFCP11000A
        // this.eaiCommObj.auth = 'S'
        // this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600045_S'
        // this.eaiCommObj.params = {}
        // Object.assign(this.eaiCommObj.params, this.ds_search)
        // TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack, this.fn_error)
        
        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600045_S'
        Object.assign(this.eaiCommObj.params, this.ds_search)
        TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack)
        
      },
      
      /**********************************************************************************
       TSServiceUtil.invoke 의 callBack
       **********************************************************************************/
      async fn_callBack ( res ) {
        
        // Object.assign(this.ds_detail, res.data) // invoke 실행할때 this.eaiCommObj.response.push('ds_detail') 의 값으로 대체함  
        /**
         * ds_detail 로 respose 가 정해졌으면 hid값을 설정한다. 
         * this.ds_detail.hid_
         * pParams.response.indexOf('ds_detail')
         */

        /********************
         * DataSet Setting
         *******************/
        const t_eaiId = res.commHeaderVO.eaiId
        const resData = Object.assign({}, res.data)
        resData.procDt = TSCommUtil.gfn_dateReplace(1, resData.procDt)

        if ( TSCommUtil.gfn_trim(resData.errorCode) != '' ) {
          this.fn_AlertPopup(0,{content : resData.errorMsg}) 
          return false
        }

        if ( t_eaiId == 'C392_F10600043_S' ) {
          this.cmb_csIvtdRatingC = defaultCsIvtdRatingC // 고객성향 dropDown dataSetting
          Object.assign(this.ds_detail, resData)
        } else if ( t_eaiId == 'C392_F10600045_S' ) { 
          Object.assign(this.ds_suikInfo, resData)
        } 

        if ( t_eaiId == "C392_F10600043_S" ) {
          const lv_procDt = this.ds_detail.procDt;
          const lv_valdTrmYys = this.ds_detail.valdTrmYys;
          const rtnYys = lv_valdTrmYys == '' ? '' :  TSCommUtil.gfn_plusYear(lv_procDt, Number(lv_valdTrmYys)); 
          this.moRange = [lv_procDt, rtnYys]
          this.fn_enableControl("A"); //입력항목 enable
          this.enabledList.cnm = false // 이름 input enable

          if ( ! TSServiceUtil.isNull(this.ds_detail.cnm) ) {
            this.ds_detail.estbRegSysTc = 2 // 시스템구분 - 신탁
            // 유효기간 moRagne Computed 로 해결 
          } 
          if ( this.ds_detail.ques01 == "1" || this.ds_detail.ques01 == "4" || this.ds_detail.ques01 == "5") 
            this.ds_detail.ques14 = "1" //YES
          if ( this.ds_detail.ques01 == "5" ) {
            // 80 세 이상일 때 추가 (21.03.09)
            this.ds_detail.ques14 = "1" //YES
            // this.alert("만" + superAge + "세 이상 초고령자의 경우 유언대용신탁, 부동산신탁, 유가증권신탁, 치매신탁 외 상품가입이 불가합니다.");
            this.fn_AlertPopup(2, { content : "만" + this.superAge + "세 이상 초고령자의 경우 유언대용신탁, 부동산신탁, 유가증권신탁, 치매신탁 외 상품가입이 불가합니다." } )
          }

          this.enabledList.btn_confirm = true // 확인버튼 사용가능하도록
          this.fn_enableControl("F"); //입력항목 enable-수익data 처리후에
          this.suik_check = "T"; // 조회버튼 다시 클릭시 수익부터 조회하도록

          // 2022-07-19 add 당일 재진단 시 3, 13번 항목은 최초 선택에서  변경 불가 S
          if (this.ds_detail.cnt == 0) 
          {
            // 당일 첫번째 진단
            this.endabledQues.ques03 = true
            this.endabledQues.ques13 = true
            this.fn_chkGenSpivrTc() // 전문 금융소비자 상태 체크 팝업
          }
          else if (this.ds_detail.cnt <= 2) 
          {
            // 당일 재진단
            let lv_Ques03 = this.ds_detail.ques03
            let lv_Ques13 = this.ds_detail.ques13

            this.endabledQues.ques03 = TSServiceUtil.isNull(lv_Ques03) || lv_Ques03 == 0 ? true : false
            this.endabledQues.ques13 = TSServiceUtil.isNull(lv_Ques13) || lv_Ques13 == 0 ? true : false

            if ( !this.retryInsertRsnCheck ) {
              this.fn_retryInsertRsn("open"); // 재진단 팝업창 오픈
            }
            this.fn_chkGenSpivrTc() // 전문 금융소비자 상태 체크 팝업
          }
          else if (this.ds_detail.cnt > 2) 
          {
            // 설문 문항 모두 disabled
            this.fn_enableControl("T")

            // 당일 진단회수 초과 (3회)
            // this.fn_AlertPopup(0,{ content : '금일 투자성향 재진단 가능횟수(3회)를 초과했습니다.'} )

            this.fn_AlertPopup(0, {
                content : "금일 투자성향 재진단 가능횟수(3회)를 초과했습니다." , 
                confirm: true , 
                single: true , 
                confirmFunc: this.fn_chkGenSpivrTc , 
            })
          }
        } else if ( t_eaiId == "C392_F10600042_S" ) { // insertTFCP11000
          // 등록완료후
          this.fn_enableControl("A"); //입력항목 enable
          this.enabledList.btn_confirm = false
          this.enabledList.rcno = true
          this.enabledList.cnm = true
         
          // ASR190700447 고객성향설문등록에 따른 분석결과지 자동출력
          this.ds_detail.bizDate = this.lv_basInfo.busyDate 
          
          
          // 실명번호를 이용한 csId 조회 
          let lv_Vm = this
          let cdiInfo = await TSInfoUtil.gfn_getCdiInfo(lv_Vm, this.ds_search)
          this.csId = TSCommUtil.gfn_isNull( cdiInfo.csId ) ? '' : cdiInfo.csId

          // 종합계좌번호 셋팅
          if ( TSCommUtil.gfn_isNull( this.tacno ) ) { // 없는 경우는 개별접근, 프로세스일 경우에는 신규신청 (MSPTS200M) 에서 값을 보내 라우터 파람으로 셋팅함
            let lv_tacno = await TSInfoUtil.gfn_getTacno(lv_Vm, this.ds_search)
            this.tacno = TSCommUtil.gfn_isNull( lv_tacno ) ? '' : lv_tacno
          }

          // 완료 메시지
          let t_popupObj = {
            confirm      : true,
            confirmFunc  : lv_Vm.fn_ReportPrint,
            content      : ['정상등록 되었습니다.'],
            contentTitle : '',
            confirmData: {
              tacno: lv_Vm.tacno,      // 종합계좌번호
              bnkbSeq: lv_Vm.bnkbSeq,  // 통장일련번호
              accSeq: lv_Vm.accSeq,    // 계좌일련번호
              csId: lv_Vm.csId,        // 정보동의서 수령방법 (메일, 전화번호 정보항목) 설정관련 추가
            },
          }
          lv_Vm.fn_AlertPopup(7, t_popupObj)
        } 
        
        if ( t_eaiId == "C392_F10600045_S" ) // selectTFCP11000A
        {
          // 수익증권data조회
          if ( this.ds_suikInfo.cnm.trim() != '' ) 
          {
            if ( ! TSServiceUtil.isNull(this.ds_suikInfo.cnm) ) 
            {
              this.ds_detail.cnm = this.ds_suikInfo.cnm

              // 당일 수익에서 등록된 data인경우
              if ( this.ds_suikInfo.procDt == TSCommUtil.fn_strToday() ) 
              {
                this.fn_AlertPopup(0,{ content : '수익증권상품 가입시 등록한 설문이 있습니다. 동일한 설문으로 재등록하시기 바랍니다.'} )
                this.fn_enableControl("T"); //입력항목 disable
              }

              this.enabledList.btn_confirm = true
              this.ds_detail = Object.assign({}, this.ds_suikInfo)
              this.ds_detail.estbRegSysTc = "1" // 시스템구분 - 수익

              if ( this.ds_detail.ques01 == "1" || this.ds_detail.ques01 == "4" || this.ds_detail.ques01 == "5") 
                this.ds_detail.ques14 = "1" //YES

              if ( this.ds_detail.ques01 == "5" ) {
                // 80 세 이상일 때 추가 (21.03.09)
                this.ds_detail.ques14 = "1" //YES
                this.fn_AlertPopup(2, { content : "만" + this.superAge + "세 이상 초고령자의 경우 유언대용신탁, 부동산신탁, 유가증권신탁, 치매신탁 외 상품가입이 불가합니다." } )
              }
            }
            else 
            {
              this.suik_check = "F";
              this.fn_searchList();
            }
          }
          else 
          {
            this.suik_check = "F";
            this.fn_searchList();
          }
        }
      },

      /**********************************************************************************
       * WFtitleCRUDbtn Div 의 버튼 활성,비활성 처리
       **********************************************************************************/
      fn_enableComponent ()
      {
        this.enabledList.btn_searchList = true
        this.enabledList.btn_confirm    = false
        this.enabledList.btn_cancel     = false
        this.enabledList.btn_init       = true
      },

      /**********************************************************************************
       * 취소 : WFtitleCRUDbtn 의 callback
       **********************************************************************************/
      fn_cancel ()
      {
        // this.fn_selectRow();
        this.fn_enableComponent()

        // 취소버튼 클릭 시, 실명확인번호종류구분코드 / 실명확인번호 입력란 enable
        this.enabledList.rcnoKindTc = true
        this.enabledList.rcno = true
      },

      /**********************************************************************************
       * 확인 : WFtitleCRUDbtn 의 callback
       **********************************************************************************/
      fn_confirm ()
      {
        if ( this.hid_rcno != this.ds_search.rcno ) 
        {
          this.fn_AlertPopup(0,{ content : '재조회 후 확인 버튼을 누르세요!'} )
          return;
        }

        this.fn_transaction();
      },


      /**********************************************************************************
       * 입력 처리시 호출
       **********************************************************************************/
      fn_transaction ()
      {
        
        // 필수체크. 필수인 컴포넌트를 ','으로 구분하여 나열한다.
        // 컴포넌트명이 아닌 풀경로인점 유의
        if ( TSServiceUtil.isNull ( this.ds_search.rcnoKindTc ) ) 
        {
          // this.fn_AlertPopup(0,{ content : '실명번호종류를 입력하십시오.'} )
          // this.$refs['cmb_rcnoKindTc'].focus()
          TSCommUtil.fn_validate( this, [{ content: '실명번호종류를 입력하십시오.', ref:'cmb_rcnoKindTc' }] )
          return;
        }

        if ( TSServiceUtil.isNull ( this.ds_search.rcno ) ) 
        {
          // this.fn_AlertPopup(0,{ content : '실명번호를 입력하십시오.'} )
          // this.$refs['edt_rcnoOne'].focus()
          TSCommUtil.fn_validate( this, [{ content: '실명번호를 입력하십시오.', ref:'edt_rcnoOne' }] )
          return;
        }
        else 
        {
          let rcnoKindTcContent = '' 
          if ( this.ds_search.rcnoKindTc == "01" ) 
            rcnoKindTcContent = '주민번호의 형식이 올바르지 않습니다.'
          else
            rcnoKindTcContent = '외국인 등록번호의 형식이 올바르지 않습니다.'
         
          if (TSCommUtil.gfn_trim(this.rcnoOne).length != 6 ) 
          {
            // this.fn_AlertPopup(0,{ content : rcnoKindTcContent } )
            // this.$refs['edt_rcnoOne'].focus()
            TSCommUtil.fn_validate( this, [{ content: rcnoKindTcContent, ref:'edt_rcnoOne' }] )
            return;
          }
          if ( TSCommUtil.gfn_trim(this.valid_rcnoTwo).length != 7 )
          {
            // this.fn_AlertPopup(0,{ content : rcnoKindTcContent } )
            // this.$refs['edt_rcnoOne'].focus()
            TSCommUtil.fn_validate( this, [{ content: rcnoKindTcContent, ref:'edt_rcnoTWo' }] )
            return;
          }
          
        }

        if ( TSServiceUtil.isNull ( this.ds_detail.cnm ) ) 
        {
          // this.fn_AlertPopup(0,{ content : '고객명을 입력하십시오.'} )
          // this.$refs['edt_cnm'].focus()
          TSCommUtil.fn_validate( this, [{ content: '고객명을 입력하십시오.', ref:'edt_cnm' }] )
          return;
          
        }

        if ( this.ds_detail.cnt > 0 ) 
          this.ds_detail.retryInsertRsnTc = this.retryRsnObj.retryInsertRsnTc
        else 
          this.ds_detail.retryInsertRsnTc = '' // 추가

        // 미입력항목체크
        if ( ! this.fn_isValid() ) 
          return false;
        
        /**
         * insert Object 셋팅
         * @see 넥사크로 BindItem 리스트 참조
         */
        // const pDsDetail = Object.assign({},this.ds_detail)
        // const pDsSearch = Object.assign({},this.ds_search)
        // const pParamData = Object.assign(pDsDetail,pDsSearch)

        let pParamData = {}
        Object.assign(pParamData, this.ds_detail)
        Object.assign(pParamData, this.ds_search)

        pParamData.procDt = pParamData.procDt.replaceAll("-","")

        /**
         * 투자권유불원(확인서) 값 셋팅 
         * @see 넥사크로 BindItem 리스트 참조
         */
        if ( this.enabledList.ivivNohoYn == true )
          pParamData.ivivNohoYn = this.chk_ivivNohoYn

        this.suik_check = "F";

        // this.eaiCommObj.lv_vm = this
        // this.eaiCommObj.trnstId = 'txTSSTS14I1'
        // this.eaiCommObj.auth = 'S'
        // this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600042_S'
        // this.eaiCommObj.params = pParamData
        // TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack, this.fn_error)

        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600042_S'
        this.eaiCommObj.params = pParamData
        TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack)
      },

      


      /***************************************************************************************
        code        : 호출할 공통코드
        dsName      : 호출된 공통코드를 담을 Dataset
        selecttype  : ComboBox 최상단에 추가할 문구( A: -전체-, S:-선택-, N: 빈칸)
        
        selecttype은 추후 변경될 수 있음.
        this.fn_commonAfterOnload를 화면에 선언하면 gfn_setCommonCode처리후 호출됨.
      *****************************************************************************************/
      fn_comCode ()
      {
        // 현재일자 구하기
        this.strToday = TSCommUtil.fn_strToday()

        // var param = [
        //   {code:"510402",   	dsName:"ds_cmb5104"}, 
        //   {code:"506002",   	dsName:"ds_cmb5060"},
        //   {code:"5063",     	dsName:"ds_cmb5063"},
        //   {code:"5038",   	  dsName:"ds_cmb5038"},
        //   {code:"5114",   	  dsName:"ds_cmb5114"},
        //   {code:"5115",   	  dsName:"ds_cmb5115"}
        //   ];
        // this.gfn_setCommonCode(param);

        this.eaiCommObj.lv_vm = this
        //this.eaiCommObj.trnstId = 'txTSSTS90S1' // selectTFCP11000A
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600046_S'
        this.eaiCommObj.params = {'TUKEMK':[
                                    {cId:"510402"},  // cmb_rcnoKindTc 실명종류
                                    {cId:"5187"},    // 초고령 나이 기준
                                    {cId:"5193"},    // cmb_retryRsn 투자성향재진단 설문
                                ]}
        TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_comCodeCallBack)
      }, 

      fn_comCodeCallBack ( res ) {
        for ( let index in res.data.tukemk ) {
          let item = res.data.tukemk[index]
          switch (item.cid) 
          {
            case "5104": // 510402 
              if ( item.c == '01' || item.c == '04' ) // 주민번호, 외국인번호만 사용
                this.cmb_rcnoKindTc.push({value: item.c , text: item.cnm})
              break;
            case "5187": 
              this.superAge = item.cnm
              break;
            case "5193": // 고객성향 댕일 재진단 사유
              this.retryRsnObj.cmb_retryRsn.push({value: item.c , text: item.cnm})
              break;
          }
        }
      },

      /**********************************************************************************
       * 화면 내 입력항목 검증 : 실질적 form_onload 구현
       **********************************************************************************/
      fn_isValid ()
      {
        let validAlertMsg = []
        if (TSServiceUtil.isNull(this.ds_detail.genSpivrTc)) 
        {
          this.valid.genSpivrTc.error = true
          validAlertMsg.push({msg: '전문투자자 범위 항목이 입력되지 않았습니다.', ref: 'rdo_genSpivrTc'})
        }

        if (TSServiceUtil.isNull(this.ds_detail.ivivHopTc)) 
        {
          this.valid.ivivHopTc.error = true
          validAlertMsg.push({msg: '투자권유 희망 항목이 입력되지 않았습니다.', ref: 'rdo_ivivHopTc'})
        }

        if (!(this.ds_detail.valdTrmYys == "1") && !(this.ds_detail.valdTrmYys == "2")) 
        {
          this.valid.valdTrmYys.error = true
          validAlertMsg.push({msg: '유효기간 설정 항목이 입력되지 않았습니다.', ref: 'rdo_valdTrmYys'})
        }

        if (this.ds_detail.ivivHopTc == "1") 
        {
          if (TSServiceUtil.isNull(TSCommUtil.gfn_trim(this.ds_detail.ques01))) 
          {
            this.valid.ques01.error = true
            validAlertMsg.push({msg: '1번의 항목이 입력되지 않았습니다.', ref: 'rdo_ques01'})
          }

          if (TSServiceUtil.isNull(TSCommUtil.gfn_trim(this.ds_detail.ques02))) 
          {
            this.valid.ques02.error = true
            validAlertMsg.push({msg: '2번의 항목이 입력되지 않았습니다.', ref: 'rdo_ques02'})
          }

          if (TSServiceUtil.isNull(TSCommUtil.gfn_trim(this.ds_detail.ques03)) || TSCommUtil.gfn_trim(this.ds_detail.ques03) == 0) 
          {
            this.valid.ques03.error = true
            validAlertMsg.push({msg: '3번의 항목이 입력되지 않았습니다.', ref: 'rdo_ques03'})
          }

          if (TSServiceUtil.isNull(TSCommUtil.gfn_trim(this.ds_detail.ques04))) 
          {
            this.valid.ques04.error = true
            validAlertMsg.push({msg: '4번의 항목이 입력되지 않았습니다.', ref: 'rdo_ques04'})
          }

          if (TSServiceUtil.isNull(TSCommUtil.gfn_trim(this.ds_detail.ques05))) 
          {
            this.valid.ques05.error = true
            validAlertMsg.push({msg: '5번의 항목이 입력되지 않았습니다.', ref: 'rdo_ques05'})
          }

          if (TSServiceUtil.isNull(TSCommUtil.gfn_trim(this.ds_detail.ques06))) 
          {
            this.valid.ques06.error = true
            validAlertMsg.push({msg: '6번의 항목이 입력되지 않았습니다.', ref: 'rdo_ques06'})
          }

          if (TSServiceUtil.isNull(TSCommUtil.gfn_trim(this.ds_detail.ques07))) 
          {
            this.valid.ques07.error = true
            validAlertMsg.push({msg: '7번의 항목이 입력되지 않았습니다.', ref: 'rdo_ques07'})
          }

          if (TSServiceUtil.isNull(TSCommUtil.gfn_trim(this.ds_detail.ques08))) 
          {
            this.valid.ques08.error = true
            validAlertMsg.push({msg: '8번의 항목이 입력되지 않았습니다.', ref: 'rdo_ques08'})
          }

          if (TSServiceUtil.isNull(TSCommUtil.gfn_trim(this.ds_detail.ques09))) 
          {
            this.valid.ques09.error = true
            validAlertMsg.push({msg: '9번의 항목이 입력되지 않았습니다.', ref: 'rdo_ques09'})
          }


          if (TSServiceUtil.isNull(TSCommUtil.gfn_trim(this.ds_detail.ques10))) 
          {
            this.valid.ques10.error = true
            validAlertMsg.push({msg: '10번의 항목이 입력되지 않았습니다.', ref: 'rdo_ques10'})
          }

          if (TSServiceUtil.isNull(TSCommUtil.gfn_trim(this.ds_detail.ques11)) || TSCommUtil.gfn_trim(this.ds_detail.ques11) == 0) 
          {
            this.valid.ques11.error = true
            validAlertMsg.push({msg: '11번의 항목이 입력되지 않았습니다.', ref: 'rdo_ques11'})
          }

          if (TSServiceUtil.isNull(TSCommUtil.gfn_trim(this.ds_detail.ques12)) || TSCommUtil.gfn_trim(this.ds_detail.ques12) == 0) 
          {
            this.valid.ques12.error = true
            validAlertMsg.push({msg: '12번의 항목이 입력되지 않았습니다.', ref: 'rdo_ques12'})
          }

          if (TSServiceUtil.isNull(TSCommUtil.gfn_trim(this.ds_detail.ques13)) || TSCommUtil.gfn_trim(this.ds_detail.ques13) == 0) 
          {
            this.valid.ques13.error = true
            validAlertMsg.push({msg: '13번의 항목이 입력되지 않았습니다.', ref: 'rdo_ques13'})
          }

          if (TSServiceUtil.isNull(TSCommUtil.gfn_trim(this.ds_detail.ques14))) 
          {
            this.valid.ques14.error = true
            validAlertMsg.push({msg: '14번의 항목이 입력되지 않았습니다.', ref: 'rdo_ques14'})
          }
        }

        // 벨리데이션 팝업
        if ( TSCommUtil.gfn_length(validAlertMsg) > 0 ) { // 하나라도 있을시에 처음 하나만 팝업
          // this.fn_AlertPopup(0,{content: validAlertMsg[0].msg})
          // TSCommUtil.gfn_focus(this.$refs[validAlertMsg[0].ref])
          TSCommUtil.fn_validate( this, [{ content: validAlertMsg[0].msg, ref:validAlertMsg[0].ref }] )
          return false
        }
        return true;
      },

      /**********************************************************************************
       * gfn_setInitForm의  callback 함수 : 실질적 form_onload 구현
       **********************************************************************************/
      fn_afterFormOnload ()
      {
        this.fn_enableComponent()
        this.fn_commonAfterOnload()
        this.fn_enableControl("A") // 입력항목 enable
        // 데이터셋 row 초기화후 row추가 : row 없으면 입력 불가능.
      },

      /**********************************************************************************
       * 초기화  : WFtitleCRUDbtn 의 callback : 폼로드시 호출, 초기화버튼 클릭시 호출
       **********************************************************************************/
      fn_init () {
        this.suik_check = "T";
        this.retryInsertRsnCheck = false //2022-07-19 add 당일재진단 시 사유입력 체크

        // 화면 초기화 작업
        this.ds_detail = Object.assign({}, defaultDsDetail)
        this.valid = _.cloneDeep(defaultValid),
        this.ds_search.rcno = ''
        this.rcnoOne = ''
        this.rcnoTwo = ''
        this.lv_isClear = !this.lv_isClear ? true : false // 보안키패드 초기화
        this.moRange = ['','']
        this.cmb_csIvtdRatingC = [] // 고객성향 dropDown
        this.fn_retryInsertRsn("close");
        this.fn_afterFormOnload()
      }, 

      fn_SuccesSearch (res) {
        console.log(res)
        //this.ds_detail = this.ds_detail,res.data.body
      }, 

      /******************************************************************************
        * Function명 : fn_searchList
        * 설명       : 고객 조회 버튼 
      ******************************************************************************/
      fn_searchList () {
        this.valid = _.cloneDeep(defaultValid),
        this.retryInsertRsnCheck = false; 
        this.fn_enableControl("F")
        this.hid_rcno = this.ds_search.rcno

        // 실명번호 화면입력시
        if ( TSServiceUtil.isNull(this.ds_search.rcno) )  
        {
          // let t_popupObj = {
          //     content : '실명번호를 입력하세요.'
          //   }
          // this.fn_AlertPopup(0,t_popupObj)
          TSCommUtil.fn_validate( this, [{ content: '실명번호를 입력하세요.', ref:'edt_rcnoOne' }] )
          return;
        }

        let rcnoKindTcContent = '' 
        if ( this.ds_search.rcnoKindTc == "01" ) 
          rcnoKindTcContent = '주민번호의 형식이 올바르지 않습니다.'
        else
          rcnoKindTcContent = '외국인 등록번호의 형식이 올바르지 않습니다.'
        
        if (TSCommUtil.gfn_trim(this.rcnoOne).length != 6 ) 
        {
          TSCommUtil.fn_validate( this, [{ content: rcnoKindTcContent, ref:'edt_rcnoOne' }] )
          return;
        }
        if ( TSCommUtil.gfn_trim(this.valid_rcnoTwo).length != 7 )
        {
          TSCommUtil.fn_validate( this, [{ content: rcnoKindTcContent, ref:'edt_rcnoTwo' }] )
          return;
        }

        if (this.suik_check == "T") {
          // 수익증권영역에 등록된 고객설문지 조회
          this.fn_SuikSelect();
        } else {
          /*서비스 호출시 정보 세팅 */
          // sSvcID = "selectTFCP11000";
          // var sURL = "svc::" + sSvcID + ".do";
          // var sInDatasets = "tfcp11000VO=ds_search:";
          // var sOutDatasets = "ds_detail=tfcp11000Rst";
          // var sArgument = "";
          // var sCallbackFunc = "fn_callBack";
          // /*transaction을 호출하는 공통함수 */
          // this.gfn_callService(sSvcID, sURL, sInDatasets, sOutDatasets, sArgument, sCallbackFunc);

          // this.eaiCommObj.lv_vm = this
          // this.eaiCommObj.trnstId = 'txTSSTS14S1' 
          // this.eaiCommObj.auth = 'S'
          // this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600043_S'
          // this.eaiCommObj.params = {}
          // //this.eaiCommObj.response = ['ds_detail']
          // //this.eaiCommObj.response.push('ds_suikInfo')  
          // Object.assign(this.eaiCommObj.params, this.ds_search)
          // TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack, this.fn_error)


          this.eaiCommObj.lv_vm = this
          //this.eaiCommObj.trnstId = 'txTSSTS90S1' // selectTFCP11000A
          this.eaiCommObj.auth = 'S'
          this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600043_S'
          this.eaiCommObj.params = {}
          Object.assign(this.eaiCommObj.params, this.ds_search)
          TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack)

        }

        return false;
      },

      /**********************************************************************************
       * 조회후 enable처리(질문항목+투자권유불원여부) : 실질적 form_onload 구현
       **********************************************************************************/
      fn_enableControl (str)
      {
        
        switch (str) {
          case "E":
            //this.Div;
            break;
          case "A":
            this.endabledQues = Object.assign({}, defaultEnabledQues) // 기본 all True
            break;
          case "A_re":
            // 2022-07-19 add 재진단일 때 3번, 13번 항목은 활성화하지 않는다.
            this.endabledQues = Object.assign({}, defaultEnabledQues) // 기본 all True
            const ques03 = this.ds_detail.ques03
            const ques13 = this.ds_detail.ques13

            this.endabledQues.ques03 = TSServiceUtil.isNull(ques03) || ques03 == 0 ? true : false
            this.endabledQues.ques13 = TSServiceUtil.isNull(ques13) || ques13 == 0 ? true : false
            break;     
          case "T":
            // 문항 초기화 
            // for(let key in this.endabledQues) { // false ex)this.endabledQues.ques01 = false....
            //   this.endabledQues[key] = false 
            // }
            this.fn_setEnableQuesRdo(false)
            // this.enabledList.csIvtdRatingC = false // 고객성향
            this.enabledList.genSpivrTc = false // 일반/전문금융소비자 여부
            this.enabledList.ivivHopTc = false // 투자 권유 희망 여부
            this.enabledList.valdTrmYys = false // 유효기간 설정 여부
            this.enabledList.ivivNohoYn = false // 투자권유불원(확인서)
            break;
          case "F":
            this.endabledQues = Object.assign({}, defaultEnabledQues) // 기본 all True
            this.endabledQues.ques01 = false
            
            // this.enabledList.csIvtdRatingC = true // 고객성향
            this.enabledList.genSpivrTc = true // 일반/전문금융소비자 여부
            this.enabledList.ivivHopTc = true // 투자 권유 희망 여부
            this.enabledList.valdTrmYys = true // 유효기간 설정 여부
            this.enabledList.ivivNohoYn = true // 투자권유불원(확인서)
            break;
        }
      },

      
      /**********************************************************************************
       * 조회후처리: 실질적 form_onload 구현
       **********************************************************************************/
      fn_commonClick (obj)
      {
        this.enabledList.genSpivrTc = true
        this.enabledList.ivivHopTc = true
        this.enabledList.valdTrmYys = true

        // 일반/전문투자자여부(1.전문투자자) or 투자권유희망여부(2.고객정보미제공)
        // if ( this.rdo_genSpivrTc.value == "1" || this.rdo_ivivHopTc.value == "2" )
        if (this.ds_detail.genSpivrTc == "1") 
        {
          this.ds_detail.ivivHopTc = "2" //고객정보미제공
          this.chk_ivivNohoYn = "Y"
          this.fn_enableControl("E"); //입력항목 disable
          this.ds_detail.valdTrmYys = "2" //유효기간 2년 자동선택
          this.sta_notice = true // 전문투자자는 설문에 응답하지 않습니다.
          this.enabledList.genSpivrTc = true
        }
        else if (this.ds_detail.genSpivrTc == "2") 
        {
          if (this.fn_checkRetry()) 
          {
            // 2022-07-19 add  fn_checkRetry() false 일 때 재진단
            // 재진단 아닐 때
            this.fn_enableControl("A"); //입력항목 enable
          }
          else 
          {
            this.fn_enableControl("A_re"); //입력항목 enable
          }

          this.chk_ivivNohoYn = "N"
          this.sta_notice = false // 전문투자자는 설문에 응답하지 않습니다.
          this.ds_detail.ivivHopTc = "1"
          this.enabledList.genSpivrTc = true
        }

        // 미성년자 또는 고령자 인 경우 10번 문항 금융취약자 YES자동 선택
        if (this.ds_detail.ques01 == "1" || this.ds_detail.ques01 == "4" || this.ds_detail.ques01 == "5") 
        {
          this.ds_detail.ques14 = "1" //YES
        }
        else if (this.ds_detail.ques01 == "5") 
        {
          // 80 세 이상일 때 추가 (21.03.09)
          this.ds_detail.ques14 = "1" //YES
          this.fn_AlertPopup(2, { content : "만" + this.superAge + "세 이상 초고령자의 경우 유언대용신탁, 부동산신탁, 유가증권신탁, 치매신탁 외 상품가입이 불가합니다." } )
          // 2021-08-31 add 치매신탁 문구 추가
        }
      },

      /**********************************************************************************
       * TFCP11000에서의 당일 재진단 여부 값을 가져와 true 또는 false 반환 2022-07-19 add
       **********************************************************************************/
      fn_checkRetry ()
      {
        if (this.ds_detail.cnt > 0) 
          return false;
        else 
          return true;
      },

      /******************************************************************************
       * Function명 : fn_chkGenSpivrTc
       * 설명       : 전문 금융소비자 상태 체크 팝업
       ******************************************************************************/
      fn_chkGenSpivrTc () {
        console.log('fn_chkGenSpivrTc.....')
        if (this.ds_detail.genSpivrTc == "1") {
          // 전문투자자일 때 경고창 :
          let lv_Tmp_text = []
          lv_Tmp_text.push("전문 금융소비자 상태로 진행하시겠습니까?")
          lv_Tmp_text.push("'확인'을 선택할 시 아래의 설문내용은 저장되지 않습니다.")
          lv_Tmp_text.push("전문 금융소비자 상태로 진행하시겠습니까?")
          lv_Tmp_text.push(" ")
          lv_Tmp_text.push("일반금융소비자일 경우 '취소'를 클릭해주세요.")

          // 일반/전문 라디오 버튼 확인 필요
          this.fn_AlertPopup(2, { content : lv_Tmp_text, confirm: true, confirmFunc: this.popCallbackConfirmGenSpivrTc, closeFunc: this.popCallbackCloseGenSpivrTc } )
        }
      },
   
     /******************************************************************************
       * Function명 : fn_AlertPopup
       * 설명       : alert popup 호출(공통)
       
       * 버튼 구성 방법
       * 1. 확인(클릭시 콜백 없음) : pPopupObj.confirm == true
       * 2. 확인(클릭시 콜백 있음) : pPopupObj.confirm == true && pPopupObj.single == true
       * 3. 확인,취소(클릭시 콜백 있음) : pPopupObj.confirm == true && pPopupObj.single == false
       * 
       * 버튼 클릭 콜백 함수
       * pPopupObj.confirmFunc : function  // 확인 버튼 클릭 콜백함수
       * pPopupObj.closeFunc : function    // 취소 버튼 클릭 콜백함수
       
      ******************************************************************************/      
      fn_AlertPopup (type, pPopupObj) {

        switch (type) {
          case 0 :
            if( !TSCommUtil.gfn_isNull(pPopupObj) ) {
              TSInfoUtil.initAlertPopObj(type, this.pAlertPopupObj, pPopupObj)
            }
            this.$refs.alertPopup.fn_Open()     
            break
          case 2 :
            if( !TSCommUtil.gfn_isNull(pPopupObj) ) { // 얼럿 팝업 타입을 0으로 맞춤
              TSInfoUtil.initAlertPopObj(0, this.pAlertPopupObj, pPopupObj)
            }
            this.$refs.alertPopupSec.fn_Open()     
            break
          case 7: // 마지막 얼럿창
            TSInfoUtil.initAlertPopObj(type, this.pAlertPopupObj, pPopupObj)
            this.$refs.alertPopup.fn_Open()
            break
          case 9 :
            if( !TSCommUtil.gfn_isNull(pPopupObj) ) {
              TSInfoUtil.initAlertPopObj(type, this.pAlertPopupObj, pPopupObj)
            }
            this.$refs.alertPopup.fn_Open()            
            break
          default :
            this.$refs.alertPopup.fn_Open()
        }
        
      },

      /******************************************************************************
       * Function명 : fn_Popup_CallBack
       * 설명       : popup 컴포넌트에서 callback 재진단 체크 여부
       ******************************************************************************/
      fn_Popup_CallBack(pData) {
        this.retryInsertRsnCheck = true
      },

      /******************************************************************************
       * Function명 : fn_ReportPrint
       * 설명       : 전자서식 호출
       ******************************************************************************/
      async fn_ReportPrint () {
        let lv_Vm = this
        lv_Vm.ds_search.bizDate = lv_Vm.lv_basInfo.busyDate

        let formList = []
        // 공통필수: 전자서명 동의서 
        let TS000001 = {formId: 'TS000001'}
        TS000001.params = {
          reportParam: JSON.stringify( { csId: lv_Vm.csId } ),
          reportUrl: 'selectTFCP10000',
        }   
        formList.push(TS000001)

        let TS000006 = {formId: 'TS000006'} // TS000006_신탁 투자자정보확인서
        TS000006.params = {
          reportParam: JSON.stringify(lv_Vm.ds_search),
          reportUrl: 'reportTFCP11000',
        }
        formList.push(TS000006)
        //TSInfoUtil.commReportInfo(this, formList) 

        let fn_RstRedirect = function () {
          lv_Vm.$router.push({name:'MSPTS105M',
            params:{
              rcnoKindTc : lv_Vm.ds_search.rcnoKindTc,
              rcno : lv_Vm.ds_search.rcno,
              processGb : lv_Vm.processGb,
              tacno : lv_Vm.tacno,
              bnkbSeq : lv_Vm.bnkbSeq,
              accSeq : lv_Vm.accSeq,
            }
          })  
        }

        TSInfoUtil.commReportInfo(lv_Vm, formList, fn_RstRedirect, false, 'MSPTS104M')
      },

      async print_test () {
        this.ds_search.bizDate = this.lv_basInfo.busyDate
        let formList = []
        let TS000006 = {formId: 'TS000006'} // TS000006_신탁 투자자정보확인서
        TS000006.params = {
          reportParam: JSON.stringify(this.ds_search),
          reportUrl: 'reportTFCP11000',
        }
        formList.push(TS000006)
        //TSInfoUtil.commReportInfo(this, formList) 
        TSInfoUtil.commReportInfo(this, formList)
      },

      /******************************************************************************
       * Function명 : fn_checkProcess
       * 설명       : 프로세스에 따라 분기 처리
       ******************************************************************************/
      fn_checkProcess (pObj) {
        let lv_Vm = this

        // let isProcess = lv_Vm.getStore('tsStore').getters.getState.isProcess
        // let isLast    = lv_Vm.$bizUtil.tsUtils.getIsLastProc(lv_Vm)        
        
        let t_popupObj = {
          confirm      : true,
          confirmFunc  : pObj.confirmFunc,
          content      : pObj.content,
          contentTitle : '',
        }
        
        // return t_popupObj
        lv_Vm.fn_AlertPopup(7, t_popupObj)
      },

      /******************************************************************************
       * Function명 : fn_rmAllErrCss
       * 설명       : 해당 컴포넌트 error css 삭제
       ******************************************************************************/
      fn_rmAllErrCss () {
        for ( let idx in this.$refs ) {
          if ( this.$refs[idx] != undefined ) {
            this.$refs[idx].$el.classList.remove("error")
          }
        }
      },

      /******************************************************************************
       * Function명 : 테스트
       * 설명       : 포커스 테스트
       ******************************************************************************/
      focus_test () {
        TSCommUtil.gfn_focus(this.$refs['edt_rcnoTwo'])
      },

    }
  }
</script>

<style lang="scss">
</style>

<style scoped>
</style>
